import React, { useEffect, useState, type ReactNode } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	Link,
	Toolbar,
	Typography,
} from '@ayx/onyx-ui';
import { ChartLine } from '@ayx/icons';
import { UserProfileMenu } from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { paths } from 'enums';
import alteryxLogo from 'assets/images/alteryx-logo.svg';
import Footer from './Footer';
import type { User } from 'types';

const pagesWithFooter = [paths.landing];

function AuthedNavActions({ user }: { user: NonNullable<User> }) {
	return (
		<div className="flex items-center">
			<UserProfileMenu
				user={user}
				additionalMenuItems={[
					{
						'data-cy': 'nav-bar-dropdown-menu-my-assessments',
						startIcon: <ChartLine />,
						children: 'My Assessments',
						component: RouterLink,
						to: paths.trendData,
					},
				]}
			/>
		</div>
	);
}

function GlobalWrapper({ children }: { children: ReactNode }) {
	const location = useLocation();
	const [hasFooter, setHasFooter] = useState<boolean>(
		pagesWithFooter.includes(location.pathname)
	);
	const { isAuthenticated, session } = useAuth();

	useEffect(() => {
		setHasFooter(pagesWithFooter.includes(location.pathname));
	}, [location]);

	return (
		<Box className="bg-[#F7F8FA] min-h-screen flex flex-col justify-between overflow-x-hidden">
			<div>
				<AppBar color="default" position="static" elevation={3}>
					<Toolbar variant="dense" className="shadow-app-bar px-3">
						<Grid
							alignItems="center"
							container
							justifyContent="space-between"
							className="py-[5px] min-h-[44px] gap-2"
						>
							<div className="flex flex-col sm:flex-row gap-x-5 items-start sm:items-center">
								<Link
									href="https://www.alteryx.com"
									target="_blank"
									className="h-6"
									data-cy="nav-bar-alteryx-home-link"
								>
									<img
										src={alteryxLogo}
										className="h-full"
										alt="Alteryx"
									/>
								</Link>
								<Divider
									orientation="vertical"
									role="presentation"
									flexItem
									className="bg-divider invisible sm:visible"
								/>
								<Typography
									variant="body1"
									className="font-montserrat font-normal text-base leading-tight py-1"
								>
									Analytics Skills Assessment
								</Typography>
							</div>
							{isAuthenticated() && (
								// session.user will never be null here
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								<AuthedNavActions user={session.user!} />
							)}
						</Grid>
					</Toolbar>
				</AppBar>
				<Container
					maxWidth="xl"
					className="p-4 md:py-16 md:px-8 h-full"
				>
					{children}
				</Container>
			</div>
			{hasFooter && <Footer />}
		</Box>
	);
}

export default GlobalWrapper;
