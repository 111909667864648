const paths = {
	// base
	wildcard: '*',
	root: '/',

	// errors
	notFound: '/404',
	error: '/error',
	unauthorized: '/login',
	unauthorizedExternalUser: '/unauthorized',

	// auth utilities
	authorize: '/authorize',
	logout: '/logout',

	// main
	landing: '/landing',
	startAssessment: '/start',
	assessment: '/assessment',
	report: '/report',
	trendData: '/trend-data',
	version: '/version',
};

export default paths;
