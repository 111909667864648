import React from 'react';
import { Link } from '@ayx/onyx-ui';

export default function Footer() {
	const alteryxDomain = 'https://www.alteryx.com';
	// const { i18n } = useTranslation();

	// TODO: uncomment this effect when we have translations
	// const privacyPolicyLink = useMemo(() => {
	// 	switch (i18n.language) {
	// 		case 'en':
	// 			return `${alteryxDomain}/privacy-policy`;
	// 		default:
	// 			return `${alteryxDomain}/${i18n.language}/privacy-policy`;
	// 	}
	// }, [i18n.language]);

	const privacyPolicyLink = `${alteryxDomain}/privacy-policy`;

	return (
		<div className="bg-white py-5 mb-12 md:mb-0 text-center">
			<Link href={privacyPolicyLink}>
				{/* <Trans>Privacy Policy</Trans> */}
				Privacy Policy
			</Link>
		</div>
	);
}
