import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import {
	AppWrapper,
	UnsupportedPage,
	type Theme,
	checkVitals,
} from '@automation-analytics/component-library';
import { AyxAppWrapper } from '@ayx/onyx-ui';

import '@automation-analytics/component-library/dist/tailwind-output.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.css';

import Router from 'navigation/Router';
import { AuthProvider } from 'stores/auth';
import TelemetryProvider from 'providers/Telemetry';
import { APP_SHORT_NAME } from 'enums';
import 'services/NewRelic';
import './i18n';

const IS_UAT_OR_PROD = process.env.NODE_ENV === 'production';

const productTheme = (theme: Theme) =>
	({
		components: {
			MuiTypography: {
				styleOverrides: {
					h1: {
						fontWeight: 700,
						fontSize: '34px',
						lineHeight: 'auto',
					},
					h2: {
						fontWeight: 700,
						fontSize: '22px',
						lineHeight: 'auto',
					},
					subtitle1: {
						fontWeight: 400,
						fontSize: '18px',
						lineHeight: '24px',
						color: theme.palette.text.primary,
					},
				},
			},
		},
	} as Theme);

export default function App() {
	return <Router />;
}

export function WrappedApp() {
	return (
		<React.StrictMode>
			<AppWrapper theme={productTheme} appShortName={APP_SHORT_NAME}>
				<AuthProvider>
					<TelemetryProvider>
						<App />
						<ToastContainer icon={false} closeButton={false} />
					</TelemetryProvider>
				</AuthProvider>
			</AppWrapper>
		</React.StrictMode>
	);
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const clientError = checkVitals().shift();

if (!clientError) {
	if (!IS_UAT_OR_PROD) {
		import('@axe-core/react').then((axe) => {
			axe.default(React, ReactDOM, 1000);
			root.render(<WrappedApp />);
		});
	} else {
		root.render(<WrappedApp />);
	}
} else {
	root.render(
		<AyxAppWrapper theme={productTheme}>
			<UnsupportedPage reason={clientError} />
		</AyxAppWrapper>
	);
}
