import React, {
	createContext,
	useCallback,
	useMemo,
	type ReactNode,
} from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import PHASES, { ENABLE_SEASONAL_CONTENT } from '../constants';

interface ISeasonalContext {
	// query function for checking a specific phase
	isActive: (phaseName: string, year?: number) => boolean;

	// returns true if any phase is active
	isContentActive: () => boolean;
}

const currentYear = moment().year();

const initialContext: ISeasonalContext = {
	isActive: () => false,
	isContentActive: () => false,
};

const SeasonalContext = createContext<ISeasonalContext>(initialContext);

function SeasonalProvider({ children }: { children: ReactNode }) {
	const [params] = useSearchParams();
	const forceEnableContent = params && params.has(ENABLE_SEASONAL_CONTENT);

	const isActive = useCallback(
		(phaseName: string, year = currentYear): boolean => {
			const startKey = `${phaseName.toUpperCase()}_START_${year}`;
			const endKey = `${phaseName.toUpperCase()}_END_${year}`;

			// if invalid phase name
			if (!PHASES[startKey] || !PHASES[endKey]) {
				return false;
			}

			// we can force content to show with a flag in non-prod environments
			if (forceEnableContent) {
				return true;
			}

			// otherwise, perform normal phase check
			const now = moment();

			return (
				now.isAfter(PHASES[startKey]) && now.isBefore(PHASES[endKey])
			);
		},
		[forceEnableContent]
	);

	const isContentActive = useCallback((): boolean => {
		// todo: evaluate isActive across every listed phase
		return isActive('halloween');
	}, [isActive]);

	const seasonalStore: ISeasonalContext = useMemo(
		() => ({
			isActive,
			isContentActive,
		}),
		[isContentActive, isActive]
	);

	return (
		<SeasonalContext.Provider value={seasonalStore}>
			{children}
		</SeasonalContext.Provider>
	);
}

export default SeasonalProvider;
export { SeasonalContext };
