import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'utils/toasts';
import type { AlertSeverity } from 'types';

export default function NavigateWithToast({
	to,
	message,
	severity = 'error',
}: {
	to: string;
	message: string;
	severity?: AlertSeverity;
}) {
	toast(message, severity as AlertSeverity);
	return <Navigate to={to} replace />;
}
