import React from 'react';

import NavigateWithToast from 'navigation/NavigateWithToast';

interface ErrorBoundaryProps {
	message?: string;
	redirectTo?: string;
}

function ErrorBoundary({
	message = 'An error has occurred',
	redirectTo = '',
}: ErrorBoundaryProps) {
	// see https://reactrouter.com/en/main/route/error-element

	return (
		<NavigateWithToast
			message={message as string}
			to={redirectTo as string}
		/>
	);
}

export default ErrorBoundary;
