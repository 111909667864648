import React from 'react';
import { CircularProgress, Container } from '@ayx/onyx-ui';

export default function PageLoading() {
	return (
		<Container className="size-full flex justify-center">
			<CircularProgress color="primary" size={36} />
		</Container>
	);
}
