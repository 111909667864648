import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from 'stores/auth';

export default function RequireAuth({ redirectTo }: { redirectTo: string }) {
	const { isAuthenticated } = useAuth();

	return isAuthenticated() ? (
		<Outlet />
	) : (
		<Navigate to={redirectTo as string} />
	);
}
