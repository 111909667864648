import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { Loading } from 'pages/utilities';
import { toastError } from 'utils/toasts';
import { errors } from 'enums';

export default function AuthTrigger({ redirectTo }: { redirectTo: string }) {
	const { login, isAuthenticated } = useAuth();
	const navigate = useNavigate();

	useEffectOnce(() => {
		// if a user is authed, prevent them from coming back to this page
		if (!isAuthenticated()) {
			login().catch(() => toastError(errors.FAULTY_SIGNIN));
		} else {
			navigate(redirectTo);
		}
	});

	return <Loading />;
}
