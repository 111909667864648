import type { IPhase } from './types';

// param used to manually enable seasonal content in non-prod environments
const ENABLE_SEASONAL_CONTENT = 'enableSeasonal';

// each PHASE should be named according to the following pattern: `<OCCASION>_<START | END>_<YEAR>`
const HALLOWEEN_START_2023 = new Date('2023-10-22T00:00:05'); // approx. midnight, 22 OCT 2023
const HALLOWEEN_END_2023 = new Date('2023-11-01T23:59:55'); // approx. midnight, 01 NOV 2023

const PHASES: IPhase = {
	HALLOWEEN_START_2023,
	HALLOWEEN_END_2023,
};

export default PHASES;
export { ENABLE_SEASONAL_CONTENT };
