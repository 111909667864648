enum ScoreIndex {
	Zero = 0,
	One,
	Two,
	Three,
	Four,
	Five,
}

export default ScoreIndex;
