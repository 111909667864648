// allows us to check urls at compile time for correct formatting
type ApiUrl = `/${string}/` | `/${string}/?${string}`;
type ApiUrls = Record<string, ApiUrl | ((...args: string[]) => ApiUrl)>;

const Endpoints = {
	// auth
	oidcLogin: '/oidc/login/',
	oidcLogout: '/oidc/logout/',
	oidcRefresh: '/oidc/refresh/',

	// user
	user: '/api/v1/me/',

	// assessments
	startNew: '/api/v1/assessments/start_new/',
	getAssessments: '/api/v1/assessments/',
	getTrendData: (page: string, rowsPerPage: string): ApiUrl =>
		`/api/v1/assessments/trend_data/?page=${page}&page_size=${rowsPerPage}`,
	getAssessmentById: (uuid: string): ApiUrl => `/api/v1/assessments/${uuid}/`,
	getDetailedReport: (uuid: string): ApiUrl =>
		`/api/v1/assessments/${uuid}/detailed_report/`,
	nextQuestion: (uuid: string): ApiUrl =>
		`/api/v1/assessments/${uuid}/next_question/`,
	postResponse: (uuid: string): ApiUrl =>
		`/api/v1/assessments/${uuid}/response/`,

	// meta
	backendDeploymentDetails: `/api/v1/deployment-details/`,
	// halloween special
	aliens: '/api/v1/special/aliens/',
} satisfies ApiUrls;

export { type ApiUrl };
export default Endpoints;
