import Endpoints from './Endpoints';
import { auxiliaryClient, client } from './clients';
import type {
	SSOLoginResponse,
	BackendDeploymentDetails,
	GetAlienResponse,
	User,
	Assessment,
	CompletedAssessmentResponse,
	HistoricalAssessments,
	LogoutPayload,
	NewAssessment,
	NextQuestionResponse,
	QuestionRequestBody,
	QuestionRequestBodyMatching,
	ReportDetails,
} from 'types';

// api functions (auxiliary)
export const getLogin = () => {
	return auxiliaryClient.get<SSOLoginResponse>(Endpoints.oidcLogin, {
		withCredentials: false,
	});
};

export const postRefresh = () => {
	return auxiliaryClient.post(Endpoints.oidcRefresh);
};

export const postLogout = () => {
	return auxiliaryClient.post<LogoutPayload>(Endpoints.oidcLogout);
};

// api functions (normal)
export const getUser = () => {
	return client.get<{ user: User }>(Endpoints.user);
};

export const getCategories = () => {
	return client.get<Assessment>(Endpoints.startNew);
};

export const startNew = (data: NewAssessment) => {
	return client.post<{ assessmentId: string }>(Endpoints.startNew, data);
};

export const nextQuestion = (uuid: string) => {
	return client.get<NextQuestionResponse | CompletedAssessmentResponse>(
		Endpoints.nextQuestion(uuid)
	);
};

export const postResponse = (
	uuid: string,
	data: QuestionRequestBodyMatching | QuestionRequestBody
) => {
	return client.post<NextQuestionResponse | CompletedAssessmentResponse>(
		Endpoints.postResponse(uuid),
		data
	);
};

export const getAssessments = () => {
	return client.get(Endpoints.getAssessments);
};

export const getAssessmentById = (uuid: string) => {
	return client.get(Endpoints.getAssessmentById(uuid));
};

export const getDetailedReport = (uuid: string) => {
	return client.get<ReportDetails>(Endpoints.getDetailedReport(uuid));
};

export const getUserAssessments = (page: number, rowsPerPage: number) => {
	return client.get<HistoricalAssessments>(
		Endpoints.getTrendData(page.toString(), rowsPerPage.toString())
	);
};

export const getBackendDeploymentDetails = () => {
	return client.get<BackendDeploymentDetails>(
		Endpoints.backendDeploymentDetails
	);
};

export const getAliens = () => {
	return client.get<GetAlienResponse>(Endpoints.aliens);
};

export const postAliens = (path: string) => {
	return client.post<GetAlienResponse>(Endpoints.aliens, { urlPath: path });
};
