import {
	type AxiosInstance,
	type AxiosError,
	type AxiosResponse,
	type InternalAxiosRequestConfig,
} from 'axios';
import { get, has } from 'lodash';
import { objectToSnake } from 'ts-case-convert';

import { errors } from 'enums';
import { AUTH_ERROR_CODES } from './constants';
import { beginLogout, objectToCamel } from './utils';
import Endpoints from './Endpoints';

// allows us to set and watch for a custom flag to prevent infinite looping requests
interface AxiosRequestConfigRetryable extends InternalAxiosRequestConfig {
	isRetry?: boolean;
}

// custom extension that allows us to skip camelCasing our responses. this is used for endpoints that return payloads that have property names that do not convert to camelCase well
declare module 'axios' {
	export interface AxiosRequestConfig {
		bypassCaseConversion?: boolean;
	}
}

// converts outgoing data in requests from camelCase to snake_case
const reqSnakeCase = (config: InternalAxiosRequestConfig) => {
	return { ...config, data: objectToSnake(config.data) };
};

// converts incoming data in responses from snake_case to camelCase
const resCamelCase = (response: AxiosResponse) => {
	return { ...response, data: objectToCamel(response.data) };
};

const resCamelCaseBypass = (response: AxiosResponse) => {
	if (response.config.bypassCaseConversion) {
		return response;
	}

	return { ...response, data: objectToCamel(response.data) };
};

const auxResErrorHandler = (error: Error | AxiosError) => {
	const isAxiosError = get(error, 'isAxiosError', false);
	const status: number | false = get(error, 'response.status', false);

	if (
		!isAxiosError ||
		status === false ||
		!AUTH_ERROR_CODES.includes(status)
	) {
		// if the error isn't auth related just reject it
		return Promise.reject(error);
	}

	// if we make it in here, we have an expired refresh token and backend has crashed,
	// restarted, or updated. in any case, frontend state is out of sync with backend state,
	// so we need to clear everything and start fresh
	return beginLogout();
};

const resErrorHandler = (
	client: AxiosInstance,
	auxClient: AxiosInstance,
	error: Error | AxiosError
) => {
	const isAxiosError = get(error, 'isAxiosError', false);

	if (!isAxiosError) {
		return Promise.reject(error);
	}

	const { response, config: originalRequest } = error as AxiosError;
	const shouldRetry = originalRequest !== undefined;

	if (!shouldRetry) {
		// 'error.config' is potentially undefined
		// if we don't have a request to retry, just return the full error
		return Promise.reject(error);
	}

	if (!has(response, 'data') || !has(response, 'status')) {
		// this can occur when a request exceeds the timeout and gets canceled by axios
		return Promise.reject(error);
	}

	const { data, status } = response as AxiosResponse;

	if (
		!AUTH_ERROR_CODES.includes(status) ||
		(shouldRetry && get(originalRequest, 'isRetry', false))
	) {
		// is an axios error or a retried request, but not an auth error, so reject here
		const modifiedError = {
			...error,
			response: {
				...response,
				data: objectToCamel(data),
			},
		};

		return Promise.reject(modifiedError);
	}

	if (
		has(data, 'detail') &&
		[
			errors.JWT_MISSING,
			errors.JWT_NOT_PROVIDED,
			errors.JWT_USER_NOT_FOUND,
		].includes(data.detail)
	) {
		// if we are in here, we tried to hit auth/refresh/ or an authed endpoint without sending credentials
		// prevents infinite loop
		return beginLogout();
	}

	return auxClient
		.post(Endpoints.oidcRefresh)
		.then(() => {
			// axios is stringifying the data twice
			if (originalRequest.data !== undefined) {
				originalRequest.data = JSON.parse(originalRequest.data);
			}

			// manually set a flag to look for in the preAuthClient router
			const modifiedRequest: AxiosRequestConfigRetryable = {
				...originalRequest,
				isRetry: true,
			};

			return Promise.resolve(client(modifiedRequest));
		})
		.catch((tokenError) => {
			return Promise.reject(tokenError);
		});
};

export {
	reqSnakeCase,
	resCamelCase,
	auxResErrorHandler,
	resCamelCaseBypass,
	resErrorHandler,
};
